/* FIXES
--------------------------------------------------------------------------------------------*/

[data-s-type="hero"] .ac_hero_slide_content_text_title * {
    font-size: 450%;
}

[data-s-id="home-services"] {
    padding:0;
}

[data-s-type="hero-alt"]{
    background: linear-gradient(-135deg, #a6141d, #e4232f) !important;
}

[data-s-type="hero-alt"] .ac_hero_alt_slide_content_text { 
    h1 {
        padding-top:10px;
        padding-bottom: 0px !important;
    }
}

.single-service [data-s-type="services"] .ac_item:hover{
    .ac_item_container {
        background-color: $brand-black;
    }
}

.single-service [data-s-type="services"] .ac_item_content {
    background-color: $brand-black;
}
 
[data-s-type="hero"] .ac_hero_container {
    height: 50vh !important;
    min-height: 250px;
}

[data-s-type="hero-alt"] .ac_hero_alt_container {
    height: 150px; 
}

[data-s-type="hero-alt"] .ac_hero_alt_container {
    min-height: 150px;
}

.ac_menu-2_main_content {
    a {
        font-size: 18pt;
    }
}

.ac_menu-2_logo_img {
    width: 240px;
    transition: width .3s ease;
}

img {
    display: inline-block;
    vertical-align: middle;
    max-width: 200%;
    height: auto;
    -ms-interpolation-mode: bicubic;
}

.ac_menu-2_sticky.is-stuck .ac_menu-2_logo_img {
    width: 200px;
}

.ac_content_1-1 {
    padding-left:10px;
    padding-right:10px;
}

h1, h2, h3, h4, h5, h6 {
    font-family: "Source Sans Pro", sans-serif;
    font-style: normal;
    font-weight: 400;
    color: black;
    text-rendering: optimizeLegibility;
    padding-bottom: 10px;
}


@media screen and (max-width: 49.9375em){
    .ac_menu-2_sticky{
        display: block;
    }

    

    #menu-mobile{
        list-style: none;
        width: 100%;
        margin-left: 0;
        padding-left: 0;
        li{
            display: block;
            width: 100%;
            text-align: center;
            margin: 0 0 5px 0;
            padding: 0 0 5px 0;
            border-bottom: 1px solid rgba(0,0,0,.1);
    
            a{
                text-align: center;
                display: block;
            }

            &:last-of-type{
                border-bottom: 0;
            }
        }

        .sub-menu{
            background: rgba(0,0,0,.1);
            padding: 10px 0;
            border-radius: 5px;
            li{
                border-bottom: 0;
            }
        }
    }
}

.ac_image-gallery_item_image_container {
    height:400px;
}

// LINKS
.main-container [data-s-type="section-content"] a {
    text-decoration: underline;
    color: $brand-primary-darker;
}


input[type="radio" i] {
    padding:0 !important;
    margin:0 !important;
}

.gform_wrapper ul.gfield_checkbox li, .gform_wrapper ul.gfield_radio li {
    padding:0 !important;
    margin:0 !important;
    margin-right:10px !important;
    display:inline;
}

.gform_wrapper ul.gfield_checkbox, .gform_wrapper ul.gfield_radio {
    margin-top:-26px !important;
}

//NEWS
@media screen and (min-width: 1000px){
    [data-s-id="actueel-news"][data-s-amount="3"] .ac_item:nth-child(1) {
        width: 30%; 
        max-width: 30%; 
    }
}

[data-s-type="news"][data-s-amount="3"] .ac_item{
    display: block !important;
}